* {
  box-sizing:  border-box;
  font-family: Arial;  
}

:root{
  --XdarkBlue:#021b33;
  --darkBlue:#003c78;
  --midBlue:#046a97;
  --lightBlue: #2988ad;
  --offWhite: #eff1de;
  --lightOrange: #fea534;
  --darkOrange:#ff6138;
  --darkRed:#8f0a0a;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}
